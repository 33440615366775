<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">시험실행/결과</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="$store.getters['userStore/GE_USER_COMPANY']"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="grid01Search.ASP_NEWCUST_KEY"
            >
            <template v-slot:label>
                회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'PLT018','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="grid01Search.LM_TY"
            >
            </v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            name="name" label="시험명" id="id" outlined hide-details=""
            v-model="grid01Search.LM_ID_NM"
            >
          </v-text-field>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
                label="전체시험기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="grid01Search.START_DATE"
                no-title
                dark
                color="#F7AFAE"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="grid01Search.END_DATE"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="grid01Search.START_DATE"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="selectRtnLmData()"> 조회 </v-btn>
        </div>
      </div>
    </div>
    <!-- 시험 리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">시험 리스트</h2>
      <div class="box-ct">
        <v-data-table
          dense
          :headers="grid01Headers"
          :items="grid01Data"
          item-key="LM_ID"
          hide-default-footer
          class="grid-default"
          fixed-header
          height="264px"
          @click:row="setGrid01Select"
          :no-data-text="noDataTable"
          :item-class="isActiveRow"
        >
        </v-data-table>
      </div>
    </div>

    <!-- 문항 정보 -->
    <div class="box-wrap">
      <div class="mt-3">
        <h2 class="tit-h2 d-flex">문항 정보
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnStart')" outlined class="btn-point" :disabled="grid01Selected.LM_DATA_ANS_YN == 'N'? false : true" @click="showDialog('M410301P01')">시험 시작</v-btn>
          </div>
          <v-dialog max-width="1748" persistent v-model="dialogM410301P01" v-if="dialogM410301P01"
            content-class="square-modal modal-white"
          >
            <M410301P01
              headerTitle="시험 기획"
              :grid01Selected="grid01Selected"
              @hide="hideDialog('M410301P01')"
              @submit="submitAns()"
            >
            </M410301P01>
          </v-dialog>
        </h2>
        <div class="table-form mt-2">
          <table>
            <caption class="hide"> 항목</caption>
            <colgroup>
              <col width="">
              <col width="">
              <col width="">
              <col width="">
              <col width="">
              <col width="">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">구분</th>
                <th scope="col">객관식(단식)</th>
                <th scope="col">객관식(복식)</th>
                <th scope="col">서술형</th>
                <th scope="col">OX형</th>
                <th scope="col">총점</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>점수</td>
                <td class="text-center">{{grid02Data.SC_TY1 != undefined ? grid02Data.SC_TY1 + "점": "-"}}</td>
                <td class="text-center">{{grid02Data.SC_TY2 != undefined ? grid02Data.SC_TY2 + "점": "-"}}</td>
                <td class="text-center">{{grid02Data.SC_TY3 != undefined ? grid02Data.SC_TY3 + "점": "-"}}</td>
                <td class="text-center">{{grid02Data.SC_TY4 != undefined ? grid02Data.SC_TY4 + "점": "-"}}</td>
                <td class="text-center">{{grid02Data.SC_TOT != undefined ? grid02Data.SC_TOT + "점": "-"}}</td>
              </tr>
              <tr>
                <td>문항수</td>
                <td class="text-center">{{grid02Data.COUNT_TY1 != undefined ? grid02Data.COUNT_TY1 + "항목" : "-"}}</td>
                <td class="text-center">{{grid02Data.COUNT_TY2 != undefined ? grid02Data.COUNT_TY2 + "항목" : "-"}}</td>
                <td class="text-center">{{grid02Data.COUNT_TY3 != undefined ? grid02Data.COUNT_TY3 + "항목" : "-"}}</td>
                <td class="text-center">{{grid02Data.COUNT_TY4 != undefined ? grid02Data.COUNT_TY4 + "항목" : "-"}}</td>
                <td class="text-center">{{grid02Data.COUNT_TOT != undefined ? grid02Data.COUNT_TOT + "항목" : "-"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 나의 채점 정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">나의 채점 정보
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" :disabled="grid01Selected.LM_DATA_ANS_YN == 'Y' && grid01Selected.LM_GRADING_YN == 'Y'? false : true" @click="showDialog('M410401P01')">채점보기</v-btn>
        </div>
        <v-dialog max-width="1748" persistent v-model="dialogM410401P01" v-if="dialogM410401P01"
          content-class="square-modal modal-white">
          <M410401P01
            headerTitle="시험 채점"
            :LM_DATA_ID="grid01Selected.LM_DATA_ID"
            :grid01Selected="grid01Selected"
            :aspCustKey="grid01Search.ASP_NEWCUST_KEY"
            @hide="hideDialog('M410401P01')"
            @submit="submitAns()"
            @callback="selectRtnLmDetail()"
            :isView="true"
          >
          </M410401P01>
        </v-dialog>
      </h2>
      <div>
        <v-text-field
          class="form-inp"
          name="name" label="시험명" id="id" outlined hide-details=""
          v-model="grid01Selected.LM_ID_NM"
          disabled
          >
        </v-text-field>
        <v-text-field
          class="form-inp ml-2"
          name="name" label="유형타입" id="id" outlined hide-details=""
          v-model="grid01Selected.LM_TY_NM"
          disabled
          >
        </v-text-field>
        <v-text-field
          class="form-inp ml-2"
          name="name" label="시험대상자" id="id" outlined hide-details=""
          v-model="grid01Selected.LM_DATA_US_NM"
          disabled
          >
        </v-text-field>
        <v-text-field
          class="form-inp ml-2"
          name="name" label="최종점수" id="id" outlined hide-details=""
          v-model="grid01Selected.LM_DATA_RST_SUM"
          disabled
          >
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'
import M410301P01 from './M410301P01.vue'
import M410401P01 from './M410401P01.vue'
export default {
  name: "MENU_M410301", //name은 'MENU_' + 파일명 조합
  components: {
    M410301P01,
    M410401P01,
  },
  mixins : [mixin],
  data() {
    return {
      dialogM410301P01 : false,
      dialogM410401P01 : false,
      menu1 : false,
      grid01Headers:[
        {
          text: "No",
          align: "center",
          value: "LM_ID",
          width: "80px",
          sortable: true,
        },
        { text: "회사구분", value: "ASP_CUST_NM", align: "center", sortable: true },
        { text: "유형타입", value: "LM_TY_NM", align: "center", sortable: true },
        { text: "시험명", value: "LM_ID_NM", align: "center", sortable: true },
        { text: "진행상태", value: "LM_PG_ST_NM", align: "center", sortable: true },
        { text: "시험시작일", value: "LM_ST_DTTM", align: "center", sortable: true },
        { text: "시험종료일", value: "LM_EN_DTTM", align: "center", sortable: true },
        { text: "제한시간(분)", value: "LM_LIM_TIME", align: "center", sortable: true },
        { text: "문제수", value: "LM_EVA_RST_COUNT", align: "center", sortable: true },
        { text: "인원", value: "LM_DATA_COUNT", align: "center", sortable: true },
        { text: "제출여부", value: "LM_DATA_ANS_YN", align: "center", sortable: true },
        { text: "미제출자", value: "LM_NON_DONE_CNT", align: "center", sortable: true },
        { text: "총점", value: "LM_EVA_SUM", align: "center", sortable: true },
      ],
      grid01Data:[],
      grid01Selected: {},
      grid01Search:{
        ASP_NEWCUST_KEY : "",
        LM_TY : "",
        LM_NM : "",
        START_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        END_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      grid02Data : {},
      groupCdList : ['PLT012','PLT018', 'PLT019', 'PLT020', 'PLT021'],
      allCodeList : [],
      noDataTable:"검색된 결과가 없습니다.",
    };
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  },
  methods: {
    setGrid01Select(item){
      this.grid01Selected = item;
      this.selectRtnLmDetail();
    },
    async selectRtnLmData(){
      this.grid01Selected = {};
      this.grid02Data = {};
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/lm/execut/list";
        requestData.headers["SERVICE"] = "phone.lm.execut";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Search;
        requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.grid01Data = response.DATA;
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }

      }
    },
    async selectRtnLmDetail(){
      let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/lm/execut/detail/inqire";
        requestData.headers["SERVICE"] = "phone.lm.execut.detail";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Selected;
        requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.grid02Data = response.EVA_DETAIL[0];
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
    },
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    submitAns(){
      this.dialogM410301P01 = false;
      this.grid01Selected.LM_DATA_ANS_YN = 'Y';
    },
    isActiveRow(item){
      return item.LM_DATA_ID == this.grid01Selected.LM_DATA_ID? 'active':'';
    },
  },
  computed: {
    searchDateRangeText(){
      var newStartDate = this.grid01Search.START_DATE;
      var newEndDate = this.grid01Search.END_DATE;
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style>

</style>
